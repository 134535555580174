import { ActionIcon, ActionIconProps, Center, Divider, Loader, Menu, Alert, Text, Tooltip } from '@mantine/core';
import { modals } from '@mantine/modals';
import MonacoEditor from '@monaco-editor/react';
import {
	IconAlertCircle,
	IconArrowBackUp,
	IconArrowForwardUp,
	IconArrowsMaximize,
	IconBlockquote,
	IconBold,
	IconBrandVimeo,
	IconBrandYoutube,
	IconCircleCheck,
	IconClick,
	IconEye,
	IconH1,
	IconH2,
	IconH3,
	IconH4,
	IconH5,
	IconH6,
	IconHeading,
	IconHelp,
	IconItalic,
	IconLink,
	IconList,
	IconListNumbers,
	IconPhoto,
	IconSeparator,
	IconStrikethrough,
	IconTable,
	IconUpload,
	IconVolume,
} from '@tabler/icons-react';
import { IDisposable, editor } from 'monaco-editor';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import ImportFromWordModal from './ImportFromWordModal';
import InsertImageModal from './InsertImageModal';
import InsertMultiChoiceQuestionModal from './InsertMultiChoiceQuestionModal';
import MarkdownLink from '@dr-pam/common-components/Components/Content/MarkdownLink';
import CachedImage from '@dr-pam/common-components/Components/Content/CachedImage';
import InsertLinkModal, { InsertLinkModalProps } from './InsertLinkModal';
import { useMarkdownService } from '@dr-pam/markdown';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import PromptModal from '../modals/PromptModal';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import StringUtils from '@dr-pam/common-components/Utils/StringUtils';
import styled from '@emotion/styled';
import InsertAudioModal from './InsertAudioModal';
import { useS3ObjectStorageService } from '@dr-pam/common-components/Services/S3ObjectStorageService';

export type MarkdownEditorProps = {
	className?: string;
	uploadFileNamePrefix: string;
	initialValue?: string;
	onChange?: (value: string) => void;
	toolbarChildren?: React.ReactNode;
	showQuestionsInToolbar?: boolean;
	showDocxImport?: boolean;
	customModals?: {
		InsertLinkModal?: React.ComponentType<InsertLinkModalProps>;
	};
};

export default function MarkdownEditor(props: MarkdownEditorProps) {
	const {
		className,
		uploadFileNamePrefix,
		initialValue,
		onChange,
		toolbarChildren,
		showQuestionsInToolbar,
		showDocxImport,
		customModals,
	} = props;

	const s3ObjectStorageService = useS3ObjectStorageService();
	const markdownService = useMarkdownService();

	const wrapperElRef = useRef<HTMLDivElement>(null);
	const loaderElRef = useRef<HTMLDivElement>(null);

	const [editor, setEditor] = useState<editor.IStandaloneCodeEditor>();

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [markdownForPreview, setMarkdownForPreview] = useState(initialValue);
	const [isSideBySidePreviewOpen, setIsSideBySidePreviewOpen] = useState(true);
	const [isFullscreen, setIsFullscreen] = useState(false);

	const handleDocxUpload = useCallback(
		async (file: File) => {
			const loader = addLoader();

			try {
				const markdown = await markdownService.docxToMarkdown(file);
				removeLoader(loader);
				return markdown;
			} catch (err) {
				removeLoader(loader);
				NotificationUtils.showError(err as Error, 'Failed to upload Word document.');
				throw err;
			}
		},
		[addLoader, markdownService, removeLoader],
	);

	const handleFileUpload = useCallback(
		async (file: File) => {
			const loader = addLoader();

			try {
				const filename = `${uploadFileNamePrefix}/${Date.now()}/${StringUtils.slugifyForFilesystem(file.name)}`;

				const uploadRequest = s3ObjectStorageService.uploadFile(filename, file);
				await uploadRequest.response;
				const url = s3ObjectStorageService.getRedirectUrl(filename);

				removeLoader(loader);

				if (url && editor) {
					return url;
				} else {
					throw new Error('No uploaded file.');
				}
			} catch (err) {
				removeLoader(loader);
				NotificationUtils.showError(err as Error, 'Failed to upload file');
				throw err;
			}
		},
		[addLoader, editor, s3ObjectStorageService, removeLoader, uploadFileNamePrefix],
	);

	useEffect(() => {
		if (!editor) {
			return;
		}

		const pasteHandler = async (e: ClipboardEvent) => {
			const items = Array.from(e.clipboardData?.items ?? []);
			if (items) {
				const hasImages = items.find((i) => i.type.indexOf('image') !== -1);

				if (!hasImages) {
					return;
				}

				// By default, the editor will paste the clipboard text into the editor.
				// Seems the best way to deal with it is simply to undo it.
				editor.trigger(null, 'undo', null);

				for (let i = 0; i < items.length; i++) {
					const item = items[i];
					if (item.type.indexOf('image') !== -1) {
						const file = item.getAsFile();
						if (file) {
							const url = await handleFileUpload(file);
							editor.trigger(null, 'paste', {
								text: `![${file.name}](${url})`,
							});
						}
					}
				}
			}
		};

		let disposeOnDidPaste: IDisposable;
		const currentWrapperEl = wrapperElRef.current;

		if (editor) {
			currentWrapperEl?.addEventListener('paste', pasteHandler);
		}

		return () => {
			disposeOnDidPaste?.dispose();
			currentWrapperEl?.removeEventListener('paste', pasteHandler);
		};
	}, [editor, handleFileUpload]);

	const toolbarEventHandlers: BuildToolbarEventHandlers = useMemo(
		() =>
			({
				onUploadFile: handleFileUpload,
				onImportDocx: handleDocxUpload,
				onToggleFullscreen: () => setIsFullscreen((prev) => !prev),
				onToggleSideBySidePreview: () => setIsSideBySidePreviewOpen((prev) => !prev),
			} as BuildToolbarEventHandlers),
		[handleDocxUpload, handleFileUpload],
	);

	const toolbarButtonStates = useMemo(
		() =>
			({
				sideBySidePreview: isSideBySidePreviewOpen,
				fullscreen: isFullscreen,
			} as BuildToolbarToggleButtonState),
		[isFullscreen, isSideBySidePreviewOpen],
	);

	const toolbarOptions = useMemo<ToolbarOptions>(
		() => ({
			editor,
			eventHandlers: toolbarEventHandlers,
			buttonStates: toolbarButtonStates,
			showQuestionsInToolbar,
			showDocxImport,
			customModals,
		}),
		[editor, toolbarEventHandlers, toolbarButtonStates, showQuestionsInToolbar, showDocxImport, customModals],
	);

	const toolbarItems = useMarkdownToolbarItems(toolbarOptions);

	const handleEditorDidMount = (editor: editor.IStandaloneCodeEditor) => {
		setEditor(editor);
	};

	const preview = useMarkdownPreview(markdownForPreview, isSideBySidePreviewOpen);

	const handleEditorChange = (value?: string) => {
		onChange?.(value ?? '');
		setMarkdownForPreview(value);
	};

	useEffect(() => {
		tippy('[data-tippy-content]');
	}, [preview]);

	return (
		<StyledDiv
			className={`MarkdownEditor mde ${className ?? ''} ${isFullscreen ? 'fullscreen' : ''} ${
				isSideBySidePreviewOpen ? 'preview' : ''
			}`}
		>
			<div className={'toolbar'}>
				{toolbarItems}
				{toolbarChildren}
			</div>
			<div className={'wrapper'}>
				<MonacoEditor
					className={'editor'}
					defaultValue={initialValue}
					language="markdown"
					width="100%"
					options={{
						wordWrap: 'on',
						automaticLayout: true,
						lineNumbersMinChars: 4,
						padding: {
							top: 10,
						},
						quickSuggestions: false,
					}}
					wrapperProps={{
						ref: wrapperElRef,
					}}
					onMount={handleEditorDidMount}
					onChange={handleEditorChange}
				/>
				{isSideBySidePreviewOpen && <StyledMarkdownPreview>{preview}</StyledMarkdownPreview>}
			</div>

			{isLoading && (
				<Center ref={loaderElRef} className={'loader'}>
					<Loader />
				</Center>
			)}
		</StyledDiv>
	);
}

type ToolbarOptions = {
	editor?: editor.IStandaloneCodeEditor;
	eventHandlers?: BuildToolbarEventHandlers;
	buttonStates?: BuildToolbarToggleButtonState;
	showQuestionsInToolbar?: boolean;
	showDocxImport?: boolean;
	customModals?: MarkdownEditorProps['customModals'];
};

const useMarkdownToolbarItems = (options: ToolbarOptions) => {
	const [toolbarItems, setToolbarItems] = useState<ReactElement[]>([]);

	useEffect(() => {
		setToolbarItems(buildToolbar(options));
	}, [options]);

	return toolbarItems;
};

const useMarkdownPreview = (markdown?: string, isPreviewEnabled?: boolean) => {
	const [preview, setPreview] = useState<ReactElement>(<></>);

	const markdownService = useMarkdownService();

	useEffect(() => {
		if (markdown !== undefined && isPreviewEnabled) {
			markdownService
				.markdownToReact(markdown, {
					img: CachedImage,
					a: MarkdownLink,
				})
				.then(({ reactElement }) => {
					setPreview(reactElement);
				})
				.catch((err) => {
					setPreview(
						<Alert icon={<IconAlertCircle />} title="Invalid markdown" color="red">
							<Text>Your markdown appears to be invalid. The following error message may help:</Text>
							<Text mt="md" size="xs">
								{err.message}
							</Text>
						</Alert>,
					);
				});
		}
	}, [markdown, isPreviewEnabled, markdownService]);

	return preview;
};

export const StyledMarkdownPreview = styled.div({
	background: 'white',
	padding: 'var(--mantine-spacing-md)',
	width: '100%',
	overflow: 'auto',
	'img, iframe': {
		display: 'block',
		maxWidth: '100%',
		margin: '0 auto',
	},
	audio: {
		width: '100%',
	},
	fieldset: {
		border: 'none',
		padding: 0,
		'> label': {
			display: 'flex',
			alignItems: 'baseline',
			gap: 'var(--mantine-spacing-sm)',
			margin: '4px 0 4px 1.4rem',
			cursor: 'pointer',
		},
		'> legend': {
			padding: 0,
		},
	},
	table: {
		borderSpacing: 0,
		borderCollapse: 'collapse',
		width: '100%',
		thead: {
			background: 'var(--mantine-color-gray-2)',
		},
		'td, th': {
			padding: '4px',
			border: `1px solid var(--mantine-color-gray-4)`,
		},
	},
	blockquote: {
		background: 'var(--mantine-color-yellow-2)',
		margin: `var(--mantine-spacing-md) 0`,
		padding: 'var(--mantine-spacing-xs)',
		'> *': {
			margin: 0,
			padding: 0,
		},
	},
});

const StyledDiv = styled.div({
	height: '100%',
	width: '100%',
	overflow: 'hidden',
	display: 'grid',
	gridTemplateRows: 'auto 1fr',
	gridTemplateColumns: '1fr',

	'&.fullscreen': {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100vw',
		height: '100vh',
		zIndex: 200,
		background: 'var(--mantine-color-white)',
	},

	'&.preview': {
		'.wrapper': {
			gridTemplateColumns: '1fr 1fr',
		},
	},

	'.toolbar': {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 'var(--mantine-spacing-xs)',
		padding: 'var(--mantine-spacing-xs)',
		background: 'var(--mantine-color-gray-2)',
		borderBottom: `1px solid var(--mantine-color-gray-4)`,

		'.mantine-ActionIcon-root > svg': {
			stroke: 'var(--mantine-color-gray-7)',
		},
	},
	'.wrapper': {
		height: '100%',
		width: '100%',
		display: 'grid',
		gridTemplateColumns: '1fr',
		gridTemplateRows: '100%',
		overflow: 'hidden',

		'> section': {
			resize: 'unset',
			overflow: 'auto',
		},
	},
	'.loader': {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		zIndex: 200,
		background: 'rgba(0,0,0,0.1)',
	},
});

type InsertTextFormatterReturn = [string, number, number];
type InsertTextFormatter = (text: string) => InsertTextFormatterReturn;

const getSelectedText = (editor: editor.IStandaloneCodeEditor) => {
	const selection = editor.getSelection();
	if (selection == null) {
		return '';
	}
	return editor.getModel()?.getValueInRange(selection) ?? '';
};

const autoLengthFormatter = (originalText: string, newText: string): InsertTextFormatterReturn => {
	return [newText, newText.length, newText.length - originalText.length];
};

const insertTextAtSelection = (editor: editor.IStandaloneCodeEditor, formatter: InsertTextFormatter) => {
	const selection = editor.getSelection();
	if (selection == null) {
		return;
	}

	editor.focus();

	const text = editor.getModel()?.getValueInRange(selection);
	const formattedText = formatter(text ?? '');

	editor.executeEdits('', [
		{
			range: selection,
			text: formattedText[0],
		},
	]);

	editor.setPosition({
		lineNumber: selection?.startLineNumber ?? 0,
		column:
			selection.startColumn === selection.endColumn
				? selection.startColumn + formattedText[1]
				: selection.endColumn + formattedText[2],
	});
};

type ToolbarSpec =
	| {
			title: string;
			icon: React.ReactNode;
			children?: ToolbarSpec[];
			onClick?: () => void;
			variant?: ActionIconProps['variant'];
	  }
	| '|';

type BuildToolbarEventHandlers = {
	onUploadFile?: (file: File) => Promise<string>;
	onImportDocx?: (file: File) => Promise<string>;
	onToggleSideBySidePreview?: () => void;
	onToggleFullscreen?: () => void;
};

type BuildToolbarToggleButtonState = {
	sideBySidePreview?: boolean;
	fullscreen?: boolean;
};

const buildToolbar = (options: ToolbarOptions) => {
	const { editor, buttonStates, eventHandlers, showDocxImport, showQuestionsInToolbar, customModals } = options;

	if (!editor) {
		return [];
	}

	const toolbarItems: ToolbarSpec[] = [
		{
			title: 'Bold',
			icon: <IconBold />,
			onClick: () => {
				insertTextAtSelection(editor, (text) => [`**${text}**`, 2, 4]);
			},
		},
		{
			title: 'Italic',
			icon: <IconItalic />,
			onClick: () => {
				insertTextAtSelection(editor, (text) => [`_${text}_`, 1, 2]);
			},
		},
		{
			title: 'Strikethrough',
			icon: <IconStrikethrough />,
			onClick: () => {
				insertTextAtSelection(editor, (text) => [`~~${text}~~`, 2, 4]);
			},
		},
		{
			title: 'Heading',
			icon: <IconHeading />,
			children: [
				{
					title: 'Heading 1',
					icon: <IconH1 />,
					onClick: () => {
						insertTextAtSelection(editor, (text) => [`# ${text}`, 2, 2]);
					},
				},
				{
					title: 'Heading 2',
					icon: <IconH2 />,
					onClick: () => {
						insertTextAtSelection(editor, (text) => [`## ${text}`, 3, 3]);
					},
				},
				{
					title: 'Heading 3',
					icon: <IconH3 />,
					onClick: () => {
						insertTextAtSelection(editor, (text) => [`### ${text}`, 4, 4]);
					},
				},
				{
					title: 'Heading 4',
					icon: <IconH4 />,
					onClick: () => {
						insertTextAtSelection(editor, (text) => [`#### ${text}`, 5, 5]);
					},
				},
				{
					title: 'Heading 5',
					icon: <IconH5 />,
					onClick: () => {
						insertTextAtSelection(editor, (text) => [`##### ${text}`, 6, 6]);
					},
				},
				{
					title: 'Heading 6',
					icon: <IconH6 />,
					onClick: () => {
						insertTextAtSelection(editor, (text) => [`###### ${text}`, 7, 7]);
					},
				},
			],
		},
		'|',
		{
			title: 'Quote',
			icon: <IconBlockquote />,
			onClick: () => {
				insertTextAtSelection(editor, (text) => [`> ${text}`, 2, 2]);
			},
		},
		{
			title: 'Bullet-point list',
			icon: <IconList />,
			onClick: () => {
				insertTextAtSelection(editor, (text) => [`- ${text}`, 2, 2]);
			},
		},
		{
			title: 'Numbered list',
			icon: <IconListNumbers />,
			onClick: () => {
				insertTextAtSelection(editor, (text) => [`1. ${text}`, 3, 4]);
			},
		},
		{
			title: 'Table',
			icon: <IconTable />,
			onClick: () => {
				const modalId = 'draw-table';
				modals.open({
					modalId,
					title: 'Insert table',
					children: (
						<PromptModal
							modalId={modalId}
							prompts={{
								columns: { label: 'Number of columns' },
								rows: { label: 'Number of rows' },
							}}
							onConfirm={async (answers) => {
								insertTextAtSelection(editor, () => {
									const rows = parseInt(answers.rows, 10);
									const columns = parseInt(answers.columns, 10);

									// Generate a markdown table with the given number of rows and columns
									const header = `|${Array.from({ length: columns })
										.map(() => ' Header |')
										.join('')}`;
									const separator = `|${Array.from({ length: columns })
										.map(() => ' --- |')
										.join('')}`;
									const body = Array.from({ length: rows })
										.map(() => {
											return `|${Array.from({ length: columns })
												.map(() => ' Cell |')
												.join('')}`;
										})
										.join('\n');

									const table = `${header}\n${separator}\n${body}`;

									return [table, 0, 0];
								});
								setTimeout(() => editor.focus(), 100);
							}}
						/>
					),
				});
			},
		},
		{
			title: 'Horizontal rule',
			icon: <IconSeparator />,
			onClick: () => {
				insertTextAtSelection(editor, () => [`---`, 3, 3]);
			},
		},
		{
			title: 'Text rollover',
			icon: <IconClick />,
			onClick: () => {
				const modalId = 'draw-rollovder';
				modals.open({
					modalId,
					title: 'Insert text rollover',
					children: (
						<PromptModal
							modalId={modalId}
							prompts={{
								text: { label: 'Text to display', initialValue: getSelectedText(editor) },
								rollover: { label: 'Text to display on hover' },
							}}
							onConfirm={async (answers) => {
								insertTextAtSelection(editor, (text) =>
									autoLengthFormatter(text, `:rollover[${answers.text}(${answers.rollover})]`),
								);
								setTimeout(() => editor.focus(), 100);
							}}
						/>
					),
				});
			},
		},
		'|',
		{
			title: 'Link',
			icon: <IconLink />,
			onClick: () => {
				const modalId = 'draw-link';
				const modalProps: InsertLinkModalProps = {
					modalId,
					initialValue: getSelectedText(editor),
					onConfirm: async (markdown) => {
						insertTextAtSelection(editor, (text) => autoLengthFormatter(text, markdown));
						setTimeout(() => editor.focus(), 100);
					},
				};

				const modalChildren = customModals?.InsertLinkModal ? (
					<customModals.InsertLinkModal {...modalProps} />
				) : (
					<InsertLinkModal {...modalProps} />
				);
				modals.open({
					modalId,
					title: 'Insert link',
					children: modalChildren,
				});
			},
		},
		{
			title: 'Image',
			icon: <IconPhoto />,
			onClick: () => {
				const modalId = 'draw-image';
				modals.open({
					modalId,
					title: 'Insert image',
					children: (
						<InsertImageModal
							modalId={modalId}
							initialValues={{
								description: getSelectedText(editor),
							}}
							onConfirm={async (values) => {
								let url = values.url;

								if (values.fileToUpload) {
									if (eventHandlers?.onUploadFile) {
										url = await eventHandlers.onUploadFile(values.fileToUpload);
									} else {
										throw new Error('eventHandlers.onUploadFile is not defined');
									}
								}

								let textToInsert = `:image[${url}]`;

								const attributes = [];

								if (values.description) {
									attributes.push(`alt="${values.description}"`);
								}

								if (values.width) {
									attributes.push(`width="${values.width}"`);
								}

								if (values.height) {
									attributes.push(`height="${values.height}"`);
								}

								if (attributes.length) {
									textToInsert += `{${attributes.join(' ')}}`;
								}

								insertTextAtSelection(editor, (text) => autoLengthFormatter(text, textToInsert));

								setTimeout(() => editor.focus(), 100);
							}}
						/>
					),
				});
			},
		},
		{
			title: 'YouTube video',
			icon: <IconBrandYoutube />,
			onClick: () => {
				const modalId = 'draw-youtube';
				modals.open({
					modalId,
					title: 'Insert YouTube video',
					children: (
						<PromptModal
							modalId={modalId}
							prompts={{
								url: { label: 'Link to YouTube video' },
							}}
							onConfirm={async (answers) => {
								insertTextAtSelection(editor, (text) =>
									autoLengthFormatter(text, `::youtube[${answers.url}]`),
								);
								setTimeout(() => editor.focus(), 100);
							}}
						/>
					),
				});
			},
		},
		{
			title: 'Vimeo video',
			icon: <IconBrandVimeo />,
			onClick: () => {
				const modalId = 'draw-vimeo';
				modals.open({
					modalId,
					title: 'Insert Vimeo video',
					children: (
						<PromptModal
							modalId={modalId}
							prompts={{
								url: { label: 'Link to Vimeo video' },
							}}
							onConfirm={async (answers) => {
								insertTextAtSelection(editor, (text) =>
									autoLengthFormatter(text, `::vimeo[${answers.url}]`),
								);
								setTimeout(() => editor.focus(), 100);
							}}
						/>
					),
				});
			},
		},
		{
			title: 'MP3 audio',
			icon: <IconVolume />,
			onClick: () => {
				const modalId = 'draw-audio';
				modals.open({
					modalId,
					title: 'Insert MP3 Audio',
					children: (
						<InsertAudioModal
							modalId={modalId}
							onConfirm={async (values) => {
								let url = values.url;

								if (values.fileToUpload) {
									if (eventHandlers?.onUploadFile) {
										url = await eventHandlers.onUploadFile(values.fileToUpload);
									} else {
										throw new Error('eventHandlers.onUploadFile is not defined');
									}
								}

								const textToInsert = `:audio[${url}]`;

								insertTextAtSelection(editor, (text) => autoLengthFormatter(text, textToInsert));

								setTimeout(() => editor.focus(), 100);
							}}
						/>
					),
				});
			},
		},
		'|',
		...((showDocxImport
			? [
					{
						title: 'Import from Word',
						icon: <IconUpload />,
						onClick: () => {
							const modalId = 'import-from-word';
							modals.open({
								modalId,
								title: 'Upload from Word',
								children: (
									<ImportFromWordModal
										modalId={modalId}
										onConfirm={async (values) => {
											if (values.file && eventHandlers?.onImportDocx) {
												const markdown = await eventHandlers.onImportDocx(values.file);

												if (values.overwrite) {
													editor.getModel()?.setValue?.(markdown);
												} else {
													insertTextAtSelection(editor, (text) =>
														autoLengthFormatter(text, markdown),
													);
												}

												setTimeout(() => editor.focus(), 100);
											}
										}}
									/>
								),
							});
						},
					},
					'|',
			  ]
			: []) as ToolbarSpec[]),
		...((showQuestionsInToolbar
			? [
					{
						title: 'Multi-choice question',
						icon: <IconCircleCheck />,
						onClick: () => {
							const modalId = 'draw-multi-choice-question';
							modals.open({
								modalId,
								title: 'Insert image',
								size: '100%',
								children: (
									<InsertMultiChoiceQuestionModal
										modalId={modalId}
										initialValues={{
											question: getSelectedText(editor),
										}}
										onConfirm={async (values) => {
											let textToInsert = `:::multiple-choice-question[${values.question}]`;
											values.options.forEach((option) => {
												textToInsert += `\n:option[${option.text}]`;
												const attributes = [];
												if (option.isAnswer) {
													attributes.push('answer');
												}
												if (option.explanation) {
													attributes.push(`explanation="${option.explanation}"`);
												}
												if (attributes.length) {
													textToInsert += `{${attributes.join(' ')}}`;
												}
											});

											textToInsert += `\n:::`;

											insertTextAtSelection(editor, () => [textToInsert, 0, 0]);
										}}
									/>
								),
							});
						},
					},
					'|',
			  ]
			: []) as ToolbarSpec[]),
		{
			title: 'Toggle side-by-side preview',
			icon: <IconEye />,
			variant: buttonStates?.sideBySidePreview ? 'outline' : undefined,
			onClick: () => {
				eventHandlers?.onToggleSideBySidePreview?.();
			},
		},
		{
			title: 'Toggle fullscreen',
			icon: <IconArrowsMaximize />,
			variant: buttonStates?.fullscreen ? 'outline' : undefined,
			onClick: () => {
				eventHandlers?.onToggleFullscreen?.();
			},
		},
		'|',
		{
			title: 'Undo',
			icon: <IconArrowBackUp />,
			onClick: () => {
				editor.trigger(null, 'undo', null);
			},
		},
		{
			title: 'Redo',
			icon: <IconArrowForwardUp />,
			onClick: () => {
				editor.trigger(null, 'redo', null);
			},
		},
		{
			title: 'Markdown guide',
			icon: <IconHelp />,
			onClick: () => {
				window.open('https://www.markdownguide.org/cheat-sheet/');
			},
		},
	];

	const toolbarReactNodes = toolbarItems.map((item, i) => {
		if (item === '|') {
			return <Divider key={i} orientation="vertical" />;
		} else {
			if (item.children) {
				return (
					<Menu key={item.title}>
						<Menu.Target>
							<Tooltip label={item.title}>
								<ActionIcon variant={item.variant ?? 'subtle'}>{item.icon}</ActionIcon>
							</Tooltip>
						</Menu.Target>
						<Menu.Dropdown>
							{item.children.map((child) =>
								child === '|' ? (
									<Divider key={i} orientation="vertical" />
								) : (
									<Menu.Item key={child.title} leftSection={child.icon} onClick={child.onClick}>
										{child.title}
									</Menu.Item>
								),
							)}
						</Menu.Dropdown>
					</Menu>
				);
			} else {
				return (
					<Tooltip key={item.title} label={item.title}>
						<ActionIcon onClick={item.onClick} variant={item.variant ?? 'subtle'}>
							{item.icon}
						</ActionIcon>
					</Tooltip>
				);
			}
		}
	});

	return toolbarReactNodes;
};
